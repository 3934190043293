import  { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet,useNavigate } from 'react-router-dom';
import { Navbar,Nav,Container  } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export default function Home() {
    const userData = useSelector(state => state.userData);
    const navigate = useNavigate();

    const [expanded, setExpanded] = useState(false);

    const handleSelect = () => {
      setExpanded(false);
    };

    useEffect(() =>{
      if(userData.id===""){
        navigate('/');
      }
    }
    ,[])
  return (
    <>

<Navbar expand="lg" bg="dark" data-bs-theme="dark" expanded={expanded}>
      <Container>
      <LinkContainer to="/home">
        {/* <Navbar.Brand onClick={handleSelect}>M.O.M DISTRIBUTION</Navbar.Brand> */}
        <Navbar.Brand onClick={handleSelect}>WORLD FOOD GATEWAY</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">

          <LinkContainer to="factures">
            <Nav.Link onClick={handleSelect}>Factures</Nav.Link>
            </LinkContainer>

            <LinkContainer to="stock">
            <Nav.Link onClick={handleSelect}>Stock</Nav.Link>
            </LinkContainer>

            <LinkContainer to="achats">
            <Nav.Link onClick={handleSelect}>Achats</Nav.Link>
            </LinkContainer>

            <LinkContainer to="produits">
            <Nav.Link onClick={handleSelect}>Produits</Nav.Link>
            </LinkContainer>

            <LinkContainer to="categories">
            <Nav.Link onClick={handleSelect}>Catégories</Nav.Link>
            </LinkContainer>

            <LinkContainer to="fournisseurs">
            <Nav.Link onClick={handleSelect}>Fournisseurs</Nav.Link>
            </LinkContainer>

            <LinkContainer to="avoirs">
            <Nav.Link onClick={handleSelect}>Avoirs</Nav.Link>
            </LinkContainer>

            <LinkContainer to="clients">
            <Nav.Link onClick={handleSelect}>Clients</Nav.Link>
            </LinkContainer>
            <LinkContainer to="cheques">
            <Nav.Link onClick={handleSelect}>Chèques</Nav.Link>
            </LinkContainer>
            <LinkContainer to="charges">
            <Nav.Link onClick={handleSelect}>Charges</Nav.Link>
            </LinkContainer>
            {userData?.type==="admin" 
        ?
        <>  
            <LinkContainer to="promos">
            <Nav.Link onClick={handleSelect}>Promotions</Nav.Link>
            </LinkContainer>
            <LinkContainer to="statistiques">
            <Nav.Link onClick={handleSelect}>Statistiques</Nav.Link>
            </LinkContainer>
            <LinkContainer to="utilisateurs">
            <Nav.Link onClick={handleSelect}>Utilisateurs</Nav.Link>
            </LinkContainer>

          </>
        :
        ''
        }
          </Nav>
      
          <Nav>
            <LinkContainer onClick={handleSelect} to="parametres">
            <Nav.Link>{userData?.nom}</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>

      </Container>
    </Navbar>
    
<Outlet/>
    </>
    
  )
}
